<template>
  <b-modal id="cookie-modal" class="cookie-modal" centered>
    <template #modal-header>
      <h4>Personliga inställningar</h4>
      <inline-svg
        class="close-modal"
        @click="handleCloseClick"
        height="20"
        :src="$svg.getSvgUrl('ic-close.svg')"
        width="20"
      />
    </template>

    <p class="text small">
      Vi använder kakor (cookies) för att säkerställa att vi ger er den bästa upplevelsen när du besöker vår webbplats eller använder våra tjänster.
      Klicka på "Godkänn" för att ge ditt samtycke till att vi använder cookies för detta ändamål. Läs mer om hur vi använder cookies <span class="cookie-link" @click="handleLinkClick"
        >här.</span>
    </p>

    <!-- <CookieSettingItem
      v-for="setting in settings"
      :key="setting.id"
      :setting="setting"
    /> -->

    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <b-button
          class="save-button hover-light mr-3"
          @click="handleCloseClick"
          variant="outline-almost-black"
          >Neka</b-button
        >
        <b-button
          class="yes-button hover-light"
          @click="handleApproveClick"
          ref="yesButton"
          variant="purple"
          >Godkänn</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
const CookieSettingItem = () => import("@/components/common/CookieSettingItem");

export default {
  name: "CookieModal",
  components: {
    CookieSettingItem
  },

  data() {
    return {
      settings: [
        { id: 1, text: "Strictly necessary cookies", value: false },
        { id: 2, text: "Lorem ipsum dolor", value: false },
        { id: 3, text: "Serpentum ne delior", value: false }
      ]
    };
  },

  methods: {
    handleLinkClick() {
      this.$bvModal.hide("cookie-modal");
      if (this.$route.path === "/cookie-policy") {
        return;
      }
      this.$router.push("/cookie-policy");
    },

    handleCloseClick() {
      this.$bvModal.hide("cookie-modal");

    },

    handleApproveClick() {
      $cookies.set('cookieconsent', true)
      // enable Google Tag Manager and its cookies
      this.$gtm.enable(true);
      this.$bvModal.hide("cookie-modal");
    },

    // handleSaveClick() {
    //   this.settings.forEach(setting =>
    //     console.log(`${setting.text}: `, setting.value)
    //   );
    //   this.$bvModal.hide("cookie-modal");
    // }
  },

  watch: {
    settings: {
      deep: true,
      handler() {
        if (this.settings.every(setting => setting.value)) {
          this.$refs.yesButton.disabled = true;
        } else {
          this.$refs.yesButton.disabled = false;
        }
      }
    }
  }
};
</script>
